// Overview Modal Style

.overviewModal {
    font-family: "Aleo", serif;

    .ant-modal-content {
        padding: 0;
        border-radius: 0.938rem;
        overflow: hidden;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

        .ant-modal-header {
            text-align: center;
            padding: 1rem;
            background: $primary;
            border-top-left-radius: 0.938rem;
            border-top-right-radius: 0.938rem;

            .ant-modal-title {
                font-size: 1.5rem;
                color: $white;
            }
        }

        .ant-modal-close {
            width: 35px;
            height: 35px;
            border-radius: 0.5rem;
            background: $white;
            border: 1px solid $white;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;

            &:hover {
                background: $primary;
                border: 1px solid $white;

                .ant-modal-close-x {
                    color: $white;
                }
            }

            .ant-modal-close-x {
                font-size: 1rem;
                line-height: 0;
                color: $primary;
            }
        }

        .ant-modal-body {
            padding: 1rem;

            .modalInner {
                .form-group {
                    margin-bottom: 0.75rem;

                    .overviewLabel {
                        font-size: 0.875rem;
                        color: $black;
                        margin-bottom: 0.35rem;
                    }

                    .overviewDetail {
                        background: #f5f5f5;
                        padding: 0.75rem;
                        border-bottom: 1px solid $primary;
                        text-transform: capitalize;
                    }
                }

                .form-control {
                    height: 45px;
                    border: 0;
                    border-bottom: 1px solid #18afd3;
                    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
                }

                .erroMessageSection {
                    margin: 1rem 0;

                    p {
                        margin-bottom: 0;
                        color: red;
                    }
                }

                .ant-select-selector {
                    height: 45px;
                    border: 0;
                    border-bottom: 1px solid #18afd3;
                    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

                    .ant-select-selection-placeholder {
                        line-height: 45px;
                    }

                    .ant-select-selection-item {
                        line-height: 45px;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .ant-modal-footer {
            margin-top: 0;
            padding: 1rem;
            text-align: center;
            border-top: 1px solid $primary;
        }
    }
}