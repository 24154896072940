//Colors
$primary: #18afd3;
$secondary: #f57e57;
$secondary: #3A3A3C;
$themecolor:#4c6575;
$surface:#F5F5F5;
$white: #FFFFFF;
$black: #000000;
$success: #449D44;
$info: #31B0D5;
$warning: #EC971F;
$danger: #C9302C;
$orange: #ff6600;