//Header Style

.header {
    box-shadow:  0px 1px 2px rgba(0, 0, 0, 0.1);

    .navbar-brand {
        width: 22%;

        @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 35%
        }

        @media only screen and (max-width: 767px) {
            width: 60%
        }
    }
    
    .navbar-nav {
        font-family: 'Aleo', serif;
        .nav-item {
            margin: 0 0.5rem;
            &:last-child {
                margin-right: 0;
            }
            .nav-link {
                font-size: 1.125rem;
                font-weight: 500;
                color: #000;
                &:hover {
                    color: #18afd3;
                }
            }
        }
    }
}

.dropdown-item:hover {
    transition: all 0.3s ease-in-out;
    &:hover {
        color: $primary;
    }
    
}

.dropdown {
    .dropdown-menu {
        display: none;
    }
}

.dropdown:hover > .dropdown-menu {
    display: block;
    margin-top: 0.125em;
    margin-left: 0.125em;
}

.appointBtn {
    display: none;

    @media only screen and (max-width: 767px) {
        display: block;
        width: 100%;

        .btnNext {
            width: 100%;
        }
    }
}