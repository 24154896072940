.btnNext {
    border: 1px solid #18afd3;
    color: #18afd3;
    font-weight: 500;
    font-size: 1.125rem;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #18afd3;
        color: #FFF;
    }
    &:disabled {
        background: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
        pointer-events: inherit;
        cursor: not-allowed;
    }
}

.btnBack {
    border: 1px solid #999;
    color: #999;
    font-weight: 500;
    font-size: 1.125rem;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #999;
        color: #212529;
    }
}

.btnPay {
    all: unset;
    border: 1px solid #18afd3;
    color: #18afd3;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 0.35rem 0.75rem;
    border-radius: 0.35rem;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #18afd3;
        span {
            color: #FFF;
        }
    }

    &:active {
        border: 1px solid #18afd3 !important;
        color: #18afd3 !important;
    }
}

.btnTrack {
    width: 100%;
    height: auto;
    border: 1px solid #18afd3;
    color: #FFF;
    background: #18afd3;
    font-weight: 500;
    font-size: 1.125rem;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #fff;
        color: #18afd3;
        border: 1px solid #18afd3;
    }
}