.appointmentWrapper {
    padding: 1rem 0;

    .aboutWrapper {
        .aboutCard {
            width: 100%;
            height: 100%;
            position: relative;
            flex-direction: row;
            border-radius: 0.938rem;
            border: 0;
            background: $white;
            overflow: hidden;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

            @media only screen and (max-width: 991px) {
                flex-direction: column;
            }

            .cardCover {
                flex: 0 0 auto;
                width: 33.33333333%;
                padding-right: calc(var(--bs-gutter-x) * 0.5);

                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-right: 0;
                }
            }

            .cardBody {
                padding: 1rem 1.5rem;
                display: flex;
                align-items: center;

                .profileName {
                    font-size: 2.5rem;
                    font-family: "Segoe Script", sans-serif;
                    // font-family: 'Aleo', serif;
                    color: #085710;

                    @media only screen and (max-width: 767px) {
                        font-size: 1.5rem;
                    }
                }

                p {
                    font-size: 1rem;
                    margin-bottom: 0;
                    line-height: 1.75rem;
                    font-weight: 500;
                }

                .socialInfo {
                    margin: 1rem 0;

                    .infoIcon {
                        margin-right: 1rem;
                        text-align: center;

                        a {
                            color: #18afd3;
                            display: inline-block;
                            width: 36px;
                            height: 36px;
                            border: 1px solid #18afd3;
                            border-radius: 0.5rem;
                            line-height: 2rem;
                            box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
                            transition: all 0.3s;

                            &:hover {
                                background: #18afd3;
                                color: #fff;
                                border: 1px solid #fff;
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }
    }

    .feedbackWrapper {
        .feedbackCard {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 0.938rem;
            border: 0;
            background: #18afd3;
            overflow: hidden;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

            .card-header {
                background: none;
                border-color: #fff;

                .card-title {
                    color: #fff;
                    text-align: center;
                    margin-bottom: 0;
                    padding: 0.75rem 0;
                }
            }

            .clientFeedCard {
                width: 100%;
                height: 100%;
                position: relative;
                border-radius: 0.938rem;
                border: 0;
                overflow: hidden;
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
            }

            .clientFeedSlide {
                .topRow {
                    .icon {
                        width: 75px;
                        height: 75px;
                        margin: 0 auto;
                        background: #fff;
                        border: 2px solid #18afd3;
                        border-radius: 50px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1rem;
                        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

                        svg {
                            color: #18afd3;
                            width: 2rem;
                            height: 2rem;
                        }
                    }

                    .clientName {
                        font-size: 1.5rem;
                        color: #fff;
                        text-align: center;
                    }

                    .designation {
                        font-size: 0.875rem;
                        text-align: center;
                        color: #fff;
                        letter-spacing: 1px;
                    }
                }

                .clientFeedCard {
                    p {
                        font-size: 1rem;
                        margin-bottom: 0;
                        line-height: 1.75rem;
                        margin-bottom: 0;

                        @media only screen and (max-width: 767px) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }

    .imageWrapper {
        .imageCard {
            border-radius: 0.938rem;
            border: 0;
            overflow: hidden;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

            .card-body {
                padding: 0;
            }
        }
    }

    .videoWrapper {
        height: 100%;
        .videoCard {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 0.938rem;
            border: 0;
            background: #18afd3;
            overflow: hidden;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

            .card-header {
                background: none;
                border-color: #fff;

                .card-title {
                    color: #fff;
                    text-align: center;
                    margin-bottom: 0;
                    padding: 0.75rem 0;
                }
            }
        }
    }

    .slideImg {
        max-height: 550px;
        min-height: 533px;
        @media only screen and (max-width: 767px) {
            min-height: auto;
        }
    }

    .slideVideo {
        height: 100%;
        border: 0;
        overflow: hidden;
        border-radius: 15px;
    }

    .stepper {
        width: 100%;
        height: 100%;
    }

    .stepperWrapper {
        padding-top: 3rem;

        .stepCard {
            height: 100%;
            border: 0;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            border-radius: 15px;

            .stepInner {
                height: 100%;
            }

            .stepForm {
                height: 100%;

                @media only screen and (max-width: 767px) {
                    height: auto;
                }

                .customForm {
                    height: calc(100% - 100px);

                    .step-content {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @media only screen and (max-width: 767px) {
                            height: auto;
                        }

                        .stepFormItem {
                            padding-top: 0.5rem;
                        }
                    }
                }
            }

            .thankYouCard {
                text-align: center;
                margin-top: 1rem;
    
                .icon {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2rem;
                    background: #085710;
                    color: $white;
                    width: 55px;
                    height: 55px;
                    margin-bottom: 1rem;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.2);
    
                    @media only screen and (max-width: 767px) {
                        width: 55px;
                        height: 55px;
                        font-size: 1.5rem;
                    }
                }
    
                h2 {
                    font-size: 1.5rem;
                    font-family: "Segoe Script", sans-serif;
                    font-weight: bold;
                    color: #085710;
    
                    @media only screen and (max-width: 767px) {
                        font-size: 1.5rem;
                    }
                }
    
                h4 {
                    font-size: 1.125rem;
    
                    @media only screen and (max-width: 767px) {
                        font-size: 1rem;
                    }
                }
    
                p {
                    color: #666;
                    text-align: center;
                    margin-bottom: 0;
                }
            }

            .stepHeader {
                .ant-steps-item-finish {
                    .ant-steps-item-icon {
                        .ant-steps-icon {
                            width: 40px;
                            height: 40px;
                            font-size: 24px;
                            line-height: 32px;
                            display: inline-block;
                            border: 1px solid #18afd3;
                            border-radius: 5px;
                            color: #fff;
                            background: #18afd3;
                        }
                    }

                    .ant-steps-item-tail {
                        &:after {
                            background-color: #18afd3;
                        }
                    }

                    .ant-steps-item-content {
                        .ant-steps-item-title {
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: 1.5rem;
                        }
                    }
                }

                .ant-steps-item-process {
                    .ant-steps-item-icon {
                        .ant-steps-icon {
                            width: 40px;
                            height: 40px;
                            font-size: 24px;
                            line-height: 32px;
                            display: inline-block;
                            border: 1px solid #18afd3;
                            border-radius: 5px;
                            color: #18afd3;
                        }
                    }

                    .ant-steps-item-tail {
                        &:after {
                            background-color: #18afd3;
                        }
                    }

                    .ant-steps-item-content {
                        .ant-steps-item-title {
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: 1.5rem;
                        }
                    }
                }

                .ant-steps-item-wait {
                    .ant-steps-item-icon {
                        .ant-steps-icon {
                            width: 40px;
                            height: 40px;
                            font-size: 24px;
                            line-height: 32px;
                            display: inline-block;
                            border: 1px solid #c4c4c4;
                            border-radius: 5px;
                            color: #c4c4c4;
                        }
                    }
                }

                .ant-steps-item-content {
                    .ant-steps-item-title {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                    }
                }
            }

            .customSelect {
                .ant-select-selector {
                    height: 45px;
                    border: 0;
                    border-bottom: 1px solid #18afd3;
                    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

                    .ant-select-selection-placeholder {
                        line-height: 45px;
                    }

                    .ant-select-selection-item {
                        line-height: 45px;
                        text-transform: capitalize;
                    }
                }
            }

            .SelectPadding {
                .ant-select-selection-search {
                    padding: 0.4rem 0;
                }
            }

            .form-control,
            .ant-picker {
                height: 45px;
                border: 0;
                border-bottom: 1px solid #18afd3;
                box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
            }

            .ant-picker {
                width: 100%;

                .ant-picker-suffix {
                    color: #18afd3;
                }
            }

            .ant-form-item-label {
                label {
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }

            .btnGroup {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .btnFinish {
                    &.ms-auto {
                        @media only screen and (max-width: 1024px) {
                            margin: 1rem auto 0 !important
                        }
                    }
                }
                
            }
        }
    }
}

::-webkit-input-placeholder {
    color: #111;
}

input::placeholder {
    color: #999 !important;
}

.drInfoCard {
    height: 100%;
    @media only screen and (max-width: 767px) {
        padding-bottom: 1rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        padding-bottom: 1rem;
    }
}

iframe {
    @media only screen and (max-width: 767px) {
        height: 250px;
    }
}

.customSelectDrop {
    .ant-select-item {
        text-transform: capitalize;
    }
}

.indicateRow {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;

    .dateSlot {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin-top: 0.5rem;
        &:nth-child(2) {
            .colorDate {
                background: $success;
            }
        }
        &:nth-child(3) {
            .colorDate {
                background: #999;
            }
        }
        &:nth-child(4) {
            .colorDate {
                background: $primary;
            }
        }

        .colorDate {
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 5px;
            margin-right: 0.5rem;
            background: rgb(198, 241, 255);
        }
    }
}

.bookingCalendar {
    border: 0;
    padding: 1rem 0;

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__viewContainer {
        .react-calendar__month-view__weekdays__weekday {
            text-decoration: none;
            font-size: 0.875rem;
            font-weight: 600;
            white-space: nowrap;

            abbr[title] {
                text-decoration: none;
                cursor: inherit;
            }
        }

        .react-calendar__tile {
            font-size: 0.875rem;
            font-weight: 600;
            transition: all 0.3s;

            &.react-calendar__tile--active {
                background: $primary;
                border-radius: 5px;
                border: 1px solid $primary;
                color: $white;
            }

            // &:hover {
            //     background: transparent;
            //     border-radius: 5px;
            //     color: $primary;
            // }

            &.react-calendar__tile--now {
                background: transparent;
                color: $primary;
                &.highlighted-date {
                    background: rgb(198, 241, 255);
                    border-radius: 5px;
                    border: 1px solid $primary !important;
                
                    &.react-calendar__tile--active {
                        background: $primary;
                        border-radius: 5px;
                        border: 1px solid $primary;
                        color: $white;
                    }
                }
            }
        }

        .react-calendar__month-view__days__day--weekend {
            color: $black;
        }
    }
}
.seat-grid {
    height: 100%;
    .noSlots {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 1rem;
            background: #00809f;
            padding: 0.875rem;
            border-radius: 5px;
            color: #fff;
            margin-bottom: 0;
        }
    } 
}
.slotsGrid {
    max-height: 300px;
    min-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    padding: 0 0.5rem;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 10px;
    }

    .bookingSlot {
        border-radius: 5px;
        border: 1px solid #000;
        text-align: center;
        font-weight: 600;
        color: #666;
        margin-right: 0.5rem;
        width: 10%;
        margin-bottom: 0.5rem;
        background: #eaeaea;
        border: none;

        &.booked {
            background: #999;
        }

        &.alotNumber {
            background: $success;
            color: $white;
        }

        &.availableNumber {
            background: $primary;
            color: $white;
        }
    }
}

.highlighted-date {
    background: rgb(198, 241, 255);
    border-radius: 5px;
    border: 1px solid $primary !important;

    &:hover {
        background: $primary !important;
        color: $white !important;
    }
}

.normalText {
    text-transform: none !important;
}

.redStar {
    label {
        &::before {
            display: inline-block;
            margin-inline-end: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: "*";
        }
    }
}

.spinDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cityRadioButton  {
    display: flex;
    flex-wrap: wrap;
    &.dateRadioButton {
        label {
            &.ant-radio-button-wrapper-disabled {
                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                    color: rgba(0, 0, 0, 0.25);
                }
            }
            @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                width: 100%;
                margin-right: 0.375rem;
            }
        } 
    }
    
    label {
        flex: 0 0 30%;
        max-width: 100%;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        text-transform: capitalize;
        font-weight: 500;
        border: 0;
        border-radius: 5px;
        border-bottom: 2px solid #18afd3 !important;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
        margin-right: 0.875rem;
        margin-bottom: 0.75rem;
        border-inline-end-width: 0;
        border-block-start-width: 0;
        &:hover {
            background: $primary;
            color: $white;
        }

        &:first-child {
            border-inline-start: 0
        }

        &::before {
            display: none !important;
        }

        &.ant-radio-button-wrapper-checked {
            background: $primary;
            color: $white;
            border-color: #18afd3 !important;
            &:hover {
                color: $white;
            }
        }

        @media only screen and (max-width: 767px) {
            flex: 0 0 48%;
            width: 48%;
            margin-right: 0.375rem;
        }
    }
}

.noData {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 8rem;

    p {
        font-size: 1.5rem;
        font-weight: 500;
    }
}

.noBookedMsg {
    font-size: 1rem;
    position: absolute;
    bottom: -4rem;
    color: $danger;
    @media only screen and (max-width: 767px) {
        position: relative;
        bottom: 0;
    }
}

.pdfButton {
    position: absolute;
    bottom: 2rem;
    right: 13rem;
    z-index: 9999;
    @media only screen and (max-width: 767px) {
        position: inherit;
        margin: 1rem 0 0;
        text-align: center;
    }
}