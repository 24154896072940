.footerWrapper {
    .copyrightTextWrapper{
        width: 100%; 
        height: 100px;
        background-color: #395261; 
        display: flex;
        justify-content: center;
        align-items: center;

        .copyrightText {
            color: white; 
            text-align: center;
            margin-bottom: 0;
        }
    }
}