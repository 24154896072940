//Track Appointment Style
.trackWrapper {
   .innerWrap {
        padding: 2rem 0;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        display: flex;

        @media only screen and (max-width: 767px) {
            height: 100%;
            padding: 5rem 0;
        }

        .trackCard {
            border-radius: 0.938rem;
            border: 0;
            background: $white;
            overflow: hidden;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    
            .card-header {
                .card-title {
                    font-size: 1.375rem;
                    padding: 0.5rem;
                    text-align: center;
                    margin-bottom: 0;

                    @media only screen and (max-width: 767px) {
                        font-size: 1rem;
                    }
                }
            }
    
            .card-body {
                padding: 2rem;

                @media only screen and (max-width: 767px) {
                    padding: 2rem 1rem;
                }
            }

            .apCentre {
                text-transform: capitalize;
                color: $primary;
            }
            
            .apDate {
                color: $primary;
            }

            .aptNumber {
                font-size: 2rem;
                color: $primary;
                vertical-align: middle;
            }
    
            .form-control {
                height: 45px;
                border: 0;
                border-bottom: 1px solid #18afd3;
                box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
            }

            .customSelect {
                .ant-select-selector {
                    height: 45px;
                    border: 0;
                    border-bottom: 1px solid #18afd3;
                    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

                    .ant-select-selection-placeholder {
                        line-height: 45px;
                    }

                    .ant-select-selection-item {
                        line-height: 45px;
                        text-transform: capitalize;
                    }
                }
            }

            .slotList {
                text-align: left;
                margin-bottom: 1rem;
                background: #F5F5F5;
                padding: 0.5rem;

                .resultNumber {
                    text-transform: capitalize;
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #18afd3;
                    text-decoration: underline;
                    margin-left: 1rem;
                }
            }
        }
   }

}