//Thank You Page Style

.thankYouWrapper {
    .innerWrapper {
        width: 100%;
        height: 78vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 767px) {
            height: 76vh;
            padding: 3rem 0;
        }

        .thankYouCard {
            text-align: center;

            .icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 6rem;
                background: #085710;
                color: $white;
                width: 120px;
                height: 120px;
                margin-bottom: 2rem;
                border: 1px solid #fff;
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.2);

                @media only screen and (max-width: 767px) {
                    width: 80px;
                    height: 80px;
                    font-size: 3rem;
                }
            }

            h2 {
                font-size: 5rem;
                font-family: "Segoe Script", sans-serif;
                font-weight: bold;
                color: #085710;

                @media only screen and (max-width: 767px) {
                    font-size: 2.5rem;
                }
            }

            h4 {
                font-size: 1.125rem;
                margin-bottom: 3rem;

                @media only screen and (max-width: 767px) {
                    font-size: 1rem;
                }
            }

            p {
                color: #666;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
}