//Base Style

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    //font-family: 'Roboto', sans-serif;
    font-family: 'Aleo', serif;
    // font-family: 'Segoe Script', sans-serif;
}

.textCapital {
    text-transform: capitalize;
}